import React from 'react';
import {
  HStack,
  Flex,
  Container,
  Heading,
  Text,
  VStack,
  Button,
  SimpleGrid,
  Center,
} from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field } from 'formik';
import 'swiper/swiper.min.css';
import {
  GFLButton,
  GFLTextFilledForm,
  TextUnderline,
} from '../components/atoms';
import GFLTextArea from '../components/atoms/GFLTextArea';

import { BiCurrentLocation, BiPhoneCall, BiTime } from 'react-icons/bi';
import { Footer, Header } from '../components/molecules';
import Loader from '../components/molecules/Loader';
import { EmailIcon } from '@chakra-ui/icons';
import { MdEmail } from 'react-icons/md';
import { FaFax } from 'react-icons/fa';
import { useWindowSize } from '../hooks';

const Contact = () => {
  const { width, height } = useWindowSize();

  return (
    <>
      <Helmet>
        <title>Contact Us | GFL Group of Companies</title>

        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="icon"
        ></link>
        <link
          href="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
          rel="apple-touch-icon"
        ></link>

        <meta
          content="width=device-width, initial-scale=1.0"
          name="viewport"
        ></meta>
        <meta
          content="logistics, suppliers, shipping, warehousing, malaysian, malaysia, klang, freight, air freight, ship freight, rail freight, cargo, cargo express"
          name="keywords"
        />
        <meta
          name="description"
          content="Your solution to total cargo transport"
        />
        <meta name="robots" content="index, follow"></meta>
        <link rel="canonical" href="https://www.gflgroup.com.my" />
        <meta
          property="og:title"
          content="Contact Us - GFL | Your solution to total cargo transport"
        ></meta>
        <meta property="og:locale" content="en_US"></meta>
        <meta property="og:type" content="website"></meta>
        <meta
          property="og:description"
          content="Your solution to total cargo transport"
        ></meta>
        <meta property="og:url" content="https://www.gflgroup.com.my"></meta>
        <meta
          property="og:site_name"
          content="Contact Us - GFL | Your solution to total cargo transport"
        ></meta>
        <meta
          property="og:image"
          content="https://#213A71cloudinary.com/shaun-storage/image/upload/v1646142917/GFL_GROUP_White_Design_xjwdcv.svg"
        ></meta>
        <meta property="twitter:card" content="summary large image"></meta>
        <meta
          property="twitter:label1"
          content="Your solution to total cargo transport"
        ></meta>
      </Helmet>
      <Loader /> <Header />
      <VStack>
        <HStack
          w="100%"
          h={['20vh', '50vh', '70vh']}
          backgroundAttachment={'unset'}
          backgroundRepeat="no-repeat"
          backgroundSize={['100%', 'cover', 'cover']}
          backgroundPosition="0% center"
          backgroundImage={'/contact/banner.jpg'}
          position="sticky"
          justifyContent="center"
          pl={['1.5rem', '3rem', '6rem']}
        >
          <VStack w="100%" alignItems={'flex-start'}>
            <Heading
              fontSize={['1rem', '2.5rem', '3rem']}
              color="rgb(255, 255, 255)"
              className="title-animation"
            >
              Contact Us
            </Heading>
            <TextUnderline color="#ffffff" opacity=".8" />
          </VStack>
        </HStack>
        <HStack
          w="100%"
          h="75vh"
          backgroundAttachment={'fixed'}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          // backgroundImage={'/contact/banner.jpg'}
          position="sticky"
          backgroundSize={'cover'}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.297594652116!2d101.42733655050483!3d3.014458054795313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdab5ff07ca8c5%3A0xda1e9183415216fa!2sGulf%20Freight%20Line%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1646665992888!5m2!1sen!2smy"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </HStack>
      </VStack>
      <Container maxW="1200px" w="100%" textAlign={'center'} mt="45px">
        <SimpleGrid columns={[1, 1, 2]}>
          <VStack
            w="100%"
            px={['1rem', '1rem', '2rem']}
            py="3em"
            spacing={5}
            alignItems={['center', 'center', 'flex-start']}
          >
            <Heading textAlign={'start'}>
              {width < 768 ? 'Contact' : 'Contact Information'}
            </Heading>
            <HStack
              style={{
                marginTop: '0.8em',
              }}
              backgroundColor="#213A71"
              w="20%"
              h="5px"
            ></HStack>
            <br />
            <HStack
              w="100%"
              justifyContent={'flex-start'}
              alignItems={['flex-start', 'flex-start', 'flex-start']}
              spacing={[4, 6, 8]}
            >
              <BiPhoneCall
                style={{
                  backgroundColor: '#213A71',
                  padding: width < 768 ? '0.5em' : '1em',
                  color: '#fff',
                }}
                size={width < 768 ? '2.5em' : '4em'}
              />
              <VStack alignItems={'flex-start'}>
                <Heading size={['sm', 'md', 'md']}>+ 603-3162 5454</Heading>
                <Text textAlign={'start'}>Don't hesitate to contact us!</Text>
              </VStack>
            </HStack>

            <HStack
              w="100%"
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={[4, 6, 8]}
            >
              <FaFax
                style={{
                  backgroundColor: '#213A71',
                  padding: width < 768 ? '0.5em' : '1em',
                  color: '#fff',
                }}
                size={width < 768 ? '2.5em' : '4em'}
              />
              <VStack alignItems={'flex-start'}>
                <Heading size={['sm', 'md', 'md']}>+ 603-3162 5445</Heading>
                <Text textAlign={'start'}>Company Fax</Text>
              </VStack>
            </HStack>

            <HStack
              w="100%"
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={[4, 6, 8]}
            >
              <MdEmail
                style={{
                  backgroundColor: '#213A71',
                  padding: width < 768 ? '0.5em' : '1em',
                  color: '#fff',
                }}
                size={width < 768 ? '2.5em' : '4em'}
              />
              <VStack alignItems={'flex-start'}>
                <Heading
                  size={['sm', 'md', 'md']}
                  textAlign="start"
                  textTransform="none"
                >
                  info@isc-gfl.com.my
                </Heading>
                <Text textAlign={'start'}>Email us to ask any questions!</Text>
              </VStack>
            </HStack>

            <HStack
              w="100%"
              justifyContent={'flex-start'}
              alignItems={'center'}
              spacing={[4, 6, 8]}
            >
              <BiTime
                style={{
                  backgroundColor: '#213A71',
                  padding: width < 768 ? '0.5em' : '1em',
                  color: '#fff',
                }}
                size={width < 768 ? '2.5em' : '4em'}
              />
              <VStack alignItems={'flex-start'}>
                <Heading size={['sm', 'md', 'md']}>Office Hours</Heading>
                <Text textAlign={'start'}>
                  Mon - Fri: 9:00 - 17:30 / Sat: 9:00 - 12:30
                </Text>
                <Text textAlign={'start'}>Close on Sundays</Text>
              </VStack>
            </HStack>
          </VStack>

          <VStack
            w="100%"
            background={'#FFF'}
            alignItems={'flex-start'}
            px={['1rem', '1rem', '2rem']}
            py="3em"
          >
            <Heading textAlign={'start'}>
              Let Us Know About Your Inquiry{' '}
            </Heading>
            <HStack
              style={{
                marginTop: '0.8em',
              }}
              backgroundColor="#213A71"
              w="20%"
              h="5px"
            ></HStack>
            <br />
            <Text
              fontSize={'1.3rem'}
              color="black"
              fontWeight={'normal'}
              textAlign={'start'}
            >
              Please feel free to fill in the form below and we will get back to
              you in our shortest time.
            </Text>
            <Formik
              initialValues={{
                name: '',
                company: '',
                email: '',
                phone: '',
                help_desc: '',
              }}
              onSubmit={(data) => {
                let fullBody = `${data.help_desc} sent from ${data.name} (${data.company}) - ${data.email} - ${data.phone}`;
                window.location.href = `mailto:info@isc-gfl.com.my?subject=From Website&body=${fullBody}`;
              }}
              enableReinitialize
            >
              {(props) => (
                <Form style={{ width: '95%' }}>
                  <VStack spacing="1.5em" w="100%">
                    <Flex
                      flexDir={['column', 'column', 'row']}
                      gap="1em"
                      w="100%"
                    >
                      <Field
                        name="name"
                        component={GFLTextFilledForm}
                        onChange={props.handleChange}
                        placeholder={'Name'}
                        value={props.values.name}
                      />
                      <Field
                        name="company"
                        component={GFLTextFilledForm}
                        onChange={props.handleChange}
                        placeholder={'Company'}
                        value={props.values.company}
                      />
                    </Flex>
                    <Flex
                      flexDir={['column', 'column', 'row']}
                      gap="1em"
                      w="100%"
                    >
                      <Field
                        name="email"
                        component={GFLTextFilledForm}
                        onChange={props.handleChange}
                        placeholder={'Email Address'}
                        value={props.values.email}
                      />
                      <Field
                        name="phone"
                        component={GFLTextFilledForm}
                        onChange={props.handleChange}
                        placeholder={'Contact Number'}
                        value={props.values.phone}
                      />
                    </Flex>
                    <Field
                      name="help_desc"
                      component={GFLTextArea}
                      onChange={props.handleChange}
                      placeholder={'Your Message'}
                      value={props.values.help_desc}
                    />
                    <HStack w="100%" justifyContent={'flex-start'}>
                      <GFLButton
                        color="#FFFFFF"
                        type="submit"
                        borderRadius={'0'}
                        // backgroundColor="#213A71"
                        py="16px"
                        px="25px"
                      >
                        Submit
                      </GFLButton>
                    </HStack>
                  </VStack>
                </Form>
              )}
            </Formik>
          </VStack>
        </SimpleGrid>
      </Container>
      <Footer />
    </>
  );
};

export default Contact;
